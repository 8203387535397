import articles from '../../utilities/articles/prodStatic';
// this file should be in server, client will have slower load times because these files are too large.

const MAX_ARTICLES = 20;

export function getStaticArticles() {
  return articles;
}

export function fetchStaticArticle(url, locale) {
  let query = articles;
  query = query[locale]?.find((article) => article.url === url);
  return query;
}

export function fetchStaticArticleListByUrls(urls = [], locale) {
  let query = articles;
  query = query[locale].filter((article) => urls.includes(article.url));
  return query;
}

export function searchStaticArticles(searchString, locale) {
  let query = articles;
  query = query[locale].filter((article) => {
    const related = article?.title.toLowerCase().includes(searchString) || article.tags.includes(searchString) || article.article_description.toLowerCase().includes(searchString) || article.seo?.article_title_tag?.toLowerCase().includes(searchString);
    return related;
  });
  return query?.slice(0, MAX_ARTICLES);
}
